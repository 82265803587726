import React, {useEffect , useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as ICONS from '../icons';
import DATA_ITH from "./dataITH.json";


// -- <img src={logo} />


const columns = [
  {
    id: 'Account',
    label: 'Account',
    align: 'center',
    minWidth: 100
  },
  {
    id: 'Date',
    label: 'Transaction Date',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Type',
    label: 'Type',
    minWidth: 10,
    align: 'center'

  },
  {
    id: 'Amount',
    label: 'Amount',
    minWidth: 10,
    align: 'right'

  },
  {
    id: 'Narration',
    label: 'Transaction Narration',
    minWidth: 170,
    align: 'left'

  },
  {
    id: 'Category',
    label: 'Category',
    minWidth: 170,
    align: 'center'
  },
];

// const displayedTx = DATA_ITH;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});



export default function TransactionTableITH(props) {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [displayedTx, setRows] = React.useState(DATA_ITH);

  const usePrevious = (searchQuery) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = searchQuery
    });
    return ref.current;
  }

  const {value, date} = props;
  const prevData = usePrevious({value, date});

useEffect(() => {
    if (prevData) {
        if (props.value !== prevData.value || props.date !== prevData.date) {
            handleSearchQueryChange(props.value, props.date);
        } else if (props.value === 1 || props.date === '') {
            setRows(DATA_ITH);
        } else if (props.value === '' && props.date === '') {
          setRows(DATA_ITH);
        }
    }
});

  const handleSearchQueryChange = (keyword, date) => {
    let searchQery = keyword,
    displayedTx = DATA_ITH.filter((el) => {
        let searchValue = el.Account;
        let dateVal = el.Date;
        if (date === null) {
          return (searchValue == searchQery);
        } else {
          return (searchValue == searchQery && dateVal.includes(date));
        }
        // return ((searchValue == searchQery && dateVal.includes(date)) || (searchValue == searchQery)) && (dateVal.includes(date)) ;
    });
    setRows(displayedTx);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
            displayedTx.length == 0  ? (
              <TableRow>
                <p className="mt-3 mx-3">No Records Found</p>
              </TableRow>
            ) :
            (displayedTx.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id]
                    if (column.id === 'Category') {
                        console.log("Category value: "+value);
                        const icon = capitalize(value);
                        const DynamicComp = ICONS[icon]
                        value = <DynamicComp />
                    }
                    if (column.id == 'Date') {
                      value = value.slice(0, -3)
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            }))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={displayedTx.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
