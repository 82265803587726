export const Business = () => <img src="../icons/business.svg" alt="Business Category" title=" Category"title="Business"/>;
export const Cash = () => <img src="../icons/cash.svg" alt="Cash Category" title="Cash"/>;
export const Charges = () => <img src="../icons/charges.svg" alt="Charges Category" title="Charges"/>;
export const Education = () => <img src="../icons/education.svg" alt="Education Category" title="Education"/>;
export const Entertainment = () => <img src="../icons/entertainment.svg" alt="Entertainment Category" title="Entertainment"/>;
export const Food = () => <img src="../icons/food.svg" alt="Food Category" title="Food"/>;
export const Health = () => <img src="../icons/health.svg" alt="Health Category" title="Health"/>;
export const Income = () => <img src="../icons/income.svg" alt="Income Category" title="Income"/>;
export const Payment = () => <img src="../icons/payment.svg" alt="Payment Category" title="Payment"/>;
export const Shopping = () => <img src="../icons/shopping.svg" alt="Shopping Category" title="Shopping"/>;
export const Transport = () => <img src="../icons/transport.svg" alt="Transport Category" title="Transport"/>;
export const Travel = () => <img src="../icons/travel.svg" alt="Travel Category" title="Travel"/>;
export const Uncategorised = () => <img src="../icons/uncategorised.svg" alt="Uncategorised Category" title="Uncategorised"/>;
export const Utilities = () => <img src="../icons/utilities.svg" alt="Utilities Category" title="Utilities"/>;