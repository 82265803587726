import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import TransactionTableBISB from './BISB/transactions';
import TransactionTableBBK from './BBK/transactions';
import TransactionTableITH from './ITHMAAR/transactions';
import TransactionTableNBB from './NBB/transactions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      verticalAlign: 'bottom',
    }
  },
  input: {
    display: 'none',
  },
  button: {
    backgroundColor: 'rgba(63, 81, 181, 0.04)'
  },
  label: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      backgroundColor: '#e8e8e8'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [accNum, setAccountNum] = React.useState('');
  const [isAccountProvided, setAccountStatus] = React.useState(false);

  const [dateVal, setDate] = React.useState(null);
  const [account, setAccount] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (date) => {
    if (date !== null) {
      var today = date
      var dd = today.getDate();

      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      if(dd<10)
      {
          dd='0'+dd;
      }

      if(mm<10)
      {
          mm='0'+mm;
      }
      today = yyyy+'-'+mm+'-'+dd;
      setSelectedDate(today);
    } else {
      setSelectedDate(null);
    }
  };

  const searchHandler  = (accountId, dateSelection) => {
    if (accNum != ''){
      setAccount(accNum);
      setDate(selectedDate);
      setAccountStatus(false);
    } else {
      setAccountStatus(true);
    }
  }

  const handleChangeSelect = (event) => {
    setAccountNum(event.target.value);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="BISB" {...a11yProps(0)} />
          <Tab label="BBK" {...a11yProps(1)} />
          <Tab label="ITHMAAR" {...a11yProps(2)} />
          <Tab label="NBB" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        Transaction Details for BISB
        <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.formControl}
                         required={true}
                         error={isAccountProvided}>
              <InputLabel id="demo-simple-select-filled-label-BISB">Account Number</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-BISB"
                id="filled-basic-BISB"
                value={accNum}
                onChange={handleChangeSelect}
              >
                <MenuItem value={1}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={100000165780}>100000165780</MenuItem>
                <MenuItem value={100000268865}>100000268865</MenuItem>
                <MenuItem value={200000455951}>200000455951</MenuItem>
                <MenuItem value={200000711678}>200000711678</MenuItem>
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider className={classes.label} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-BISB"
                    label="Search Date"
                    format="yyyy-MM-dd"
                    value={selectedDate ? selectedDate : null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    clearable
                    />
            </MuiPickersUtilsProvider>
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture"
                            component="span"
                            onClick={() => searchHandler('filled-basic-BISB', 'date-picker-dialog-BISB')}
                            className={classes.button}>
                    <KeyboardReturnIcon />
                </IconButton>
            </label>
        </form>
        <TransactionTableBISB value={account} date={dateVal}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
       Transaction Details of BBK
       <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.formControl}
                         required={true}
                         error={isAccountProvided}
                         >
              <InputLabel id="demo-simple-select-filled-label-BBK">Account Number</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-BBK"
                id="filled-basic-BISB"
                value={accNum}
                onChange={handleChangeSelect}
              >
                <MenuItem value={1}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'BH10BBKU00200004590413'}>BH10BBKU00200004590413</MenuItem>
                <MenuItem value={'BH28BBKU00200004470779'}>BH28BBKU00200004470779</MenuItem>
                <MenuItem value={'BH33BBKU00200004924940'}>BH33BBKU00200004924940</MenuItem>
                <MenuItem value={'BH60BBKU00200005804535'}>BH60BBKU00200005804535</MenuItem>
                <MenuItem value={'BH83BBKU00100000130887'}>BH83BBKU00100000130887</MenuItem>
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider className={classes.label} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-BBK"
                    label="Search Date"
                    format="yyyy-MM-dd"
                    value={selectedDate ? selectedDate : null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    clearable
                    />
            </MuiPickersUtilsProvider>
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture"
                            component="span"
                            onClick={() => searchHandler('filled-basic-BBK', 'date-picker-dialog-BBK')}
                            className={classes.button}>
                    <KeyboardReturnIcon />
                </IconButton>
            </label>
        </form>
        <TransactionTableBBK value={account} date={dateVal}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
      Transaction Details of ITHMAAR
      <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.formControl}
                         required={true}
                         error={isAccountProvided}
                         >
              <InputLabel id="demo-simple-select-filled-label-ITH">Account Number</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-ITH"
                id="filled-basic-ITH"
                value={accNum}
                onChange={handleChangeSelect}
              >
                <MenuItem value={1}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={503300783701015}>503300783701015</MenuItem>
                <MenuItem value={503301593021001}>503301593021001</MenuItem>
                <MenuItem value={505301760521016}>505301760521016</MenuItem>
                <MenuItem value={511300506961013}>511300506961013</MenuItem>
                <MenuItem value={513000544391001}>513000544391001</MenuItem>
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider className={classes.label} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-ITH"
                    label="Search Date"
                    format="yyyy-MM-dd"
                    value={selectedDate ? selectedDate : null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    clearable
                    />
            </MuiPickersUtilsProvider>
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture"
                            component="span"
                            onClick={() => searchHandler('filled-basic-ITH', 'date-picker-dialog-ITH')}
                            className={classes.button}>
                    <KeyboardReturnIcon />
                </IconButton>
            </label>
        </form>
        <TransactionTableITH value={account} date={dateVal}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
      Transaction Details of NBB
      <form className={classes.form} noValidate autoComplete="off">
            <FormControl className={classes.formControl}
                         required={true}
                         error={isAccountProvided}
                         >
              <InputLabel id="demo-simple-select-filled-label-NBB">Account Number</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-NBB"
                id="filled-basic-NBB"
                value={accNum}
                onChange={handleChangeSelect}
              >
                <MenuItem value={1}>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={99459108}>99459108</MenuItem>
                <MenuItem value={99469545}>99469545</MenuItem>
                <MenuItem value={251233790}>251233790</MenuItem>
                <MenuItem value={275303403}>275303403</MenuItem>
                <MenuItem value={292532822}>292532822</MenuItem>
                <MenuItem value={293934916}>293934916</MenuItem>
                <MenuItem value={1011443317}>1011443317</MenuItem>
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider className={classes.label} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog-NBB"
                    label="Search Date"
                    format="yyyy-MM-dd"
                    value={selectedDate ? selectedDate : null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    clearable
                    />
            </MuiPickersUtilsProvider>
            <label htmlFor="icon-button-file">
                <IconButton color="primary" aria-label="upload picture"
                            component="span"
                            onClick={() => searchHandler('filled-basic-NBB', 'date-picker-dialog-NBB')}
                            className={classes.button}>
                    <KeyboardReturnIcon />
                </IconButton>
            </label>
        </form>
        <TransactionTableNBB value={account} date={dateVal}/>
      </TabPanel>
    </div>
  );
}
