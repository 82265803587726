import React, { Component } from 'react';
import './App.css';

import Header from './aggregators/header';
import ScrollableTabsButtonAuto from './aggregators/body';

class App extends Component {
  render() {
    return (
      <>
      <Header />
      <ScrollableTabsButtonAuto />
      </>
    );
  }
}

export default App;
